<script setup lang="ts">
const {filterPop, filterPopAutoSubmit} = storeToRefs(useGlobalStore());
const {$activeModalsBus} = useNuxtApp();
const show = ref(true);
function openPopup() {
  $activeModalsBus.event("openPopup");
}
watchEffect(() => {
  document.documentElement.style.overflow = filterPop.value ? "hidden" : null;
});
</script>

<template>
  <VDialog
      scroll-strategy="none"
      v-model="filterPop"
      width="100%"
      :show-close="false"
      :location="'center'"
      z-index="100"
  >
    <div class="search-element cover search-element-pop relative" style="">
      <button class="modal-close" @click="filterPop = false" aria-label="Close">
        <img
            src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/close.svg"
            alt="אייקון המסמל כפתור לסגירה"
        />
      </button>

      <div class="car-filter__title">חיפוש שטיחים לפי דגם הרכב</div>
      <div id="popFilter" class="position-relative">
        <div class="car-filter">
          <div class="car-filter__title-inner">נשמח להכיר, איזה רכב יש לך?</div>
          <div class="car-filter__inner">
            <CarFilter :auto-submit="filterPopAutoSubmit"/>
            <a class="bottom_link" href="javascript:void(0);" @click="openPopup">
              &gt;&gt; אצטרך עזרה במציאת הרכב שלי
            </a>
          </div>
        </div>
      </div>
    </div>
  </VDialog>
</template>

<style scoped lang="scss">
.search-element-pop {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.search-element .modal-close {
  right: 0px;
}

</style>
